import request from "@/utils/request";
// 新增 洗衣通商品
export const addGoods = (data) => {
  return request({
    method: "post",
    url: "clothes/goods/save",
    data,
  });
};
// 修改商品
export const updateGoods = (data) => {
  return request({
    method: "post",
    url: "clothes/goods/update",
    data,
  });
};
// 分页查询商品/库存查询
export const getGoodsPage = (params) => {
  return request({
    method: "get",
    url: "clothes/store_goods/page",
    params,
  });
};
// 商品上下架
export const updateGoodsShelf = (data) => {
  return request({
    method: "post",
    url: `clothes/goods/shelf`,
    data,
  });
};
// 删除商品
export const deleteGoods = (data) => {
  return request({
    method: "post",
    url: `clothes/goods/delete`,
    data,
  });
};
// 商品出入库明细
export const getInventoryRecordDetailPage = (params) => {
  return request({
    method: "get",
    url: `clothes/goods/inventory_record/detail_page`,
    params,
  });
};
// 分页查询商品出入库记录
export const getInventoryRecordPage = (params) => {
  return request({
    method: "get",
    url: `clothes/goods/inventory_record/page`,
    params,
  });
};
// 门店商品列表
export const getStoreGoodsQueryList = (params) => {
  return request({
    method: "get",
    url: `clothes/store_goods/query_list`,
    params,
  });
};
// 总部商品列表
export const getGoodsList = (params) => {
  return request({
    method: "get",
    url: `clothes/goods/list`,
    params,
  });
};
// 商品出入库
export const setGoodsIntoAndOut = (data) => {
  return request({
    method: "post",
    url: `clothes/goods/inventory_record/join_or_out`,
    data,
  });
};
// 出入库记录详情
export const getInventoryRecordDetail = (id) => {
  return request({
    method: "get",
    url: `clothes/goods/inventory_record/detail/${id}`,
  });
};
// 计算采购价格
export const getGoodsStorePurchaseCalculate = (data) => {
  return request({
    method: "post",
    url: `clothes/goods_store_purchase/calculate`,
    data,
  });
};
// 新增门店采购商品
export const addGoodsStorePurchase = (data) => {
  return request({
    method: "post",
    url: `clothes/goods_store_purchase/save`,
    data,
  });
};
// 分页查询采购列表
export const getGoodsStorePurchase = (params) => {
  return request({
    method: "get",
    url: `clothes/goods_store_purchase/page`,
    params,
  });
};
// 查询门店采购商品详情
export const getGoodsStorePurchaseDetail = (id) => {
  return request({
    method: "get",
    url: `clothes/goods_store_purchase/detail/${id}`,
  });
};
// 门店确认收货
export const getGoodsStorePurchaseChangeStatus = (params) => {
  return request({
    method: "get",
    url: `clothes/goods_store_purchase/confirm_receipt`,
    params,
  });
};

// 获取门店采购活动
export const getPurchaseActivity = () => {
  return request({
    method: "get",
    url: "clothes/goods/purchase_activity/store",
  });
};
// 采购支付接口
export const payGoodsStorePurchase = (data) => {
  return request({
    method: "post",
    url: "clothes/goods_store_purchase/pay",
    data,
  });
};
// 查询支付商品采购订单
export const getPayOrderQuery = (params) => {
  return request({
    method: "get",
    url: "clothes/goods_store_purchase/pay/order_query",
    params,
  });
};
// 门店商品价格列表
export const getStoreGoodsPrice = (params) => {
  return request({
    method: "get",
    url: "clothes/store_goods/list",
    params,
  });
};
// 查询用户可用现金券列表
export const getCouponuserList = (params) => {
  return request({
    method: "get",
    url: "member/couponuser/can_use_cash",
    params,
  });
};
// 创建商品订单
export const addGoodsOrder = (data) => {
  return request({
    method: "post",
    url: "order/goods_order/create",
    data,
  });
};
// 支付商品订单
export const payGoodsOrder = (data) => {
  return request({
    method: "post",
    url: "order/goods_order/pay",
    data,
  });
};
// 支付商品订单查询
export const queryPayGoodsOrder = (params) => {
  return request({
    method: "get",
    url: "order/goods_order/pay/order_query",
    params,
  });
};
// 门店分页查询商品订单
export const getGoodsOrderList = (params) => {
  return request({
    method: "get",
    url: "order/goods_order/store_page",
    params,
  });
};
// 取消商品订单
export const cancelGoodsOrder = (data) => {
  return request({
    method: "post",
    url: "order/goods_order/cancel",
    data,
  });
};
// 门店查询商品订单详情
export const getGoodsOrderDetail = (params) => {
  return request({
    method: "get",
    url: "order/goods_order/store_detail",
    params,
  });
};

// 商品出售明细
export const pageGoodsSellRecordDetail = (params) => {
  return request({
    method: "get",
    url: `clothes/goods_sell_record/page`,
    params,
  });
};
