<template>
  <div class="orderIndexView content-index">
    <div class="topView">
      <div style="padding-bottom:15px">
        <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="商品订单"
        ></el-page-header>
      </div>
      <div class="filterView">
        <div class="dateView">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div class="orderTypeView">
          <el-select
            v-model="payStatus"
            placeholder="付款状态"
            clearable
            @clear="handleFind"
          >
            <el-option
              v-for="(item, index) in payStatusOptions"
              :key="index"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="orderTypeView">
          <el-select
            v-model="orderStatus"
            clearable
            placeholder="订单状态"
            @clear="handleFind"
          >
            <el-option
              v-for="(item, index) in orderStatusOptions"
              :key="index"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="keywordView">
          <el-input
            v-model.trim="goodsOrderId"
            placeholder="请输入订单号/手机号"
            clearable
            @clear="handleFind"
            @blur="eliminate"
          ></el-input>
        </div>
        <div class="keywordView">
          <el-button
            type="success"
            @click="handleFind(1)"
            :loading="searchLoading"
            >确认</el-button
          >
        </div>
      </div>
    </div>
    <div class="orderRepView">
      <div class="headerView flexView">
        <div>序号</div>
        <div>订单号</div>
        <div style="flex:0.8">联系人</div>
        <div>电话</div>
        <div style="flex:0.5">件数</div>
        <div style="flex:0.8">订单状态</div>
        <div>下单时间</div>
        <div style="flex:1.3">付款状态</div>
        <div>实付金额</div>
      </div>
      <div
        class="listView"
        style="height: calc(100vh - 320px);"
        v-infinite-scroll="loadOrderArr"
        infinite-scroll-distance="10"
        infinite-scroll-disabled="disableLoadOrderArr"
      >
        <div
          class="orderItem flexView"
          v-for="(item, index) in orderArr"
          :key="index"
        >
          <div>{{ index + 1 }}</div>
          <div>
            <span v-if="item.orderType == 1 || item.orderType == 2">
              <el-tag class="attach" type="danger" size="mini">团</el-tag>
            </span>
            {{ item.goodsOrderId }}
          </div>
          <div style="flex:0.8">{{ item.userName }}</div>
          <div>
            {{ item.userPhone }}
          </div>
          <div style="flex:0.5">
            {{ item.goodsNum }}
          </div>
          <div style="flex:0.8">
            <el-tag>{{ item.orderStatusDesc }}</el-tag>
          </div>
          <div>{{ item.orderTime }}</div>
          <div style="flex:1.3">
            {{ item.payStatusDesc }}({{ item.payWayDesc }})
          </div>
          <div>￥{{ item.actuallyPaidPrice }}</div>
          <el-button-group class="btn-edit">
            <el-button
              type="danger"
              style="margin-right: 1px;"
              v-if="
                item.payStatusDesc == '未支付' &&
                  item.orderStatusDesc != '已取消'
              "
              @click.stop="setCancelOrder(item)"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              @click.stop="getClothesOrderDetails(item)"
            >
              详情
            </el-button>
          </el-button-group>
        </div>
        <div v-if="loadingOrderArr" class="moreItem">加载中...</div>
        <div v-if="noMoreOrderArr" class="moreItem">没有更多订单哦</div>
      </div>
    </div>
    <!-- 取消订单 -->
    <CancelOrder
      :visible="cancelOrderVisible"
      :buttonLoading="buttonLoading"
      @close="closeOrder"
      @enter="enterOrder"
    />
  </div>
</template>

<script>
import { getGoodsOrderList, cancelGoodsOrder } from "@/api/commodity";
import CancelOrder from "@/components/cancelOrder";

export default {
  name: "Order",
  components: {
    CancelOrder,
  },
  props: {},
  data() {
    return {
      payStatus: "",
      payStatusOptions: [
        {
          label: "未支付",
          val: "1",
        },
        {
          label: "已支付",
          val: "2",
        },
      ],
      orderStatus: "",
      orderStatusOptions: [
        {
          label: "待发货",
          val: "1",
        },
        {
          label: "已完成",
          val: "2",
        },
        {
          label: "已取消",
          val: "3",
        },
      ],
      orderArr: [],
      loadingOrderArr: false,
      noMoreOrderArr: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      moreList: [],
      orderNumber: "",
      goodsOrderId: "",
      phone: "",
      timeStart: new Date(),
      timeEnd: new Date(),
      dateRange: [
        this.$moment(new Date()).format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD"),
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 90 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        },
      },
      searchLoading: false,
      cancelOrderVisible: false,
      orderData: {},
      buttonLoading: false,
    };
  },
  computed: {
    disableLoadOrderArr() {
      return this.loadingOrderArr || this.noMoreOrderArr;
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleFind: function() {
      this.orderArr = [];
      this.currentPage = 1;
      this.loadOrderArr();
    },
    eliminate() {
      if (this.goodsOrderId == "") {
        return false;
      } else {
        this.dateRange = null;
      }
    },
    loadOrderArr() {
      let that = this;
      this.loadingOrderArr = true;
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        beginTime: this.dateRange === null ? "" : this.dateRange[0],
        endTime: this.dateRange === null ? "" : this.dateRange[1],
        payStatus: this.payStatus,
        orderStatus: this.orderStatus,
        goodsOrderId: this.goodsOrderId,
      };
      this.searchLoading = true;
      getGoodsOrderList({ ...params })
        .then((response) => {
          that.moreList = response.data.data.records;
          that.total = response.data.data.total;
          if (that.moreList.length === 0) {
            that.noMoreOrderArr = true;
          } else {
            that.noMoreOrderArr = false;
            that.currentPage++;
            that.moreList.forEach(function(item) {
              that.orderArr.push(item);
            });
          }
          if (that.orderArr.length >= that.total) {
            that.noMoreOrderArr = true;
          }
          that.loadingOrderArr = false;
          that.searchLoading = false;
        })
        .catch(() => {
          that.searchLoading = false;
          that.loadingOrderArr = false;
        });
    },
    //获取订单详情
    getClothesOrderDetails(item) {
      this.$router.push({
        path:"/home/more/commodityOrderDetails",
        query:{
          goodsOrderId:item.goodsOrderId
        }
      })
    },
    // 取消订单
    setCancelOrder(item) {
      this.orderData = { ...item };
      this.cancelOrderVisible = true;
    },
    closeOrder() {
      this.cancelOrderVisible = false;
    },
    enterOrder(value) {
      this.buttonLoading = true;
      cancelGoodsOrder({
        goodsOrderId: this.orderData.goodsOrderId,
        orderRemark: value,
      })
        .then((response) => {
          if (response.data.code === 0) {
            this.$message.success("取消订单成功");
            this.orderArr = this.orderArr.map((item) => {
              if (item.goodsOrderId == this.orderData.goodsOrderId) {
                item.orderStatusDesc = "已取消";
              }
              return item;
            });
            this.cancelOrderVisible = false;
          } else {
            this.$message.error(response.data.msg);
          }
          this.buttonLoading = false;
        })
        .catch(() => {
          this.buttonLoading = false;
        });
    },
    getTime() {
      var date1 = new Date();
      var year = date1.getFullYear();
      var month = date1.getMonth() + 1;
      var day = date1.getDate();
      var hours = date1.getHours();
      var minutes = date1.getMinutes();
      var seconds = date1.getSeconds();
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  padding-bottom: 70px;
  padding-top: 0;

  p span {
    font-size: 16px;
    font-weight: 700;
  }

  .el-button {
    margin-top: 10px;
    float: right;
    margin-right: 5px;
  }
}
.el-tag.el-tag--danger {
  background-color: #ffcc66;
  color: #fff;
}
.orderIndexView {
  overflow: auto;
  > .topView {
    // height: 110px;
    padding: 20px 20px 0 20px;
    /*筛选条件*/
    > .filterView {
      display: flex;
      height: 44px;
      // padding: 10px 0 0 0;

      > div {
        height: 44px;
        margin-right: 15px;
      }

      > .keywordView {
        > button {
          background: #3370ff;
          font-size: 16px;
          font-family: PingFangSC-Medium;
          color: #fff;
        }
      }
    }
  }

  .tableTitle {
    padding: 0 20px 0;

    p {
      font-size: 16px;
      font-weight: 700;

      span {
        margin: 0 10px;
      }
    }
  }

  /*内容*/
  > .orderRepView {
    > .headerView {
      font-size: 14px;
      color: #000;
      text-align: center;
    }

    > .listView {
      overflow-x: auto;
      overflow-y: auto;
      padding-bottom: 25px;

      > .orderItem {
        position: relative;
        line-height: 20px;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > label {
          cursor: pointer;
          line-height: 32px;
        }

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          height: 40px;
          width: 140px;
          display: flex;
          justify-content: center;
        }
      }

      > .moreItem {
        margin-top: 5px;
        line-height: 40px;
        font-size: 13px;
        color: #3370ff;
        text-align: center;
      }

      .orderItem:hover {
        background: rgba(66, 153, 255, 0.1);
      }

      > .orderItem:nth-child(odd) {
        background: #fff;
      }
    }
  }

  > .orderRepView .flexView {
    display: flex;
    padding: 15px 134px 15px 20px;
    align-items: center;

    > div {
      flex: 1;
    }
    > div:nth-child(1) {
      flex: 0.5;
    }
    > div:nth-child(2) {
      flex: 1.3;
    }
    > div:nth-child(4) {
      flex: 0.8;
    }
    > div:nth-child(5) {
      flex: 1.3;
    }
    > div:nth-child(8) {
      flex: 0.5;
    }
  }
}
.copyBth {
  margin-left: 5px;
  font-size: 10px;
  padding: 0;
  background-color: #fff;
  color: #3370ff;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
