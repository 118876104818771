<template>
  <el-dialog
    title="提示"
    :visible="visible"
    width="420px"
    top="42vh"
    append-to-body
    @close="closeVisible"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="0"
      class="demo-ruleForm"
    >
      <el-form-item prop="input">
        <el-input
          v-model.trim="ruleForm.input"
          clearable
          placeholder="请输入取消原因"
          type="text"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="enter" :loading="buttonLoading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "cancelOrder",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleForm: {
        input: "",
      },
      rules: {
        input: [
          { required: true, message: "请输入取消原因", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    closeVisible() {
      this.$emit("close");
      this.ruleForm = {
        input: "",
      };
    },
    close() {
      this.$emit("close");
    },
    enter() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("enter", this.ruleForm.input);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
</style>
